export const GET_SELECTED_LEVEL_FROM_UPPER_NAV_ON_CLICK = 'GET_SELECTED_LEVEL_FROM_UPPER_NAV_ON_CLICK';
export const SET_USER_NATIVE_LANGUAGE = 'SET_USER_NATIVE_LANGUAGE';
export const GET_USER_NATIVE_LANGUAGE = 'GET_USER_NATIVE_LANGUAGE';
export const SET_USER_SELECTED_COURSE = 'SET_USER_SELECTED_COURSE';
export const GET_USER_SELECTED_COURSE = 'GET_USER_SELECTED_COURSE';
export const SET_USER_BASIC_CONFIGURATION_FLAG = 'SET_USER_BASIC_CONFIGURATION_FLAG';
export const GET_USER_BASIC_CONFIGURATION_FLAG = 'GET_USER_BASIC_CONFIGURATION_FLAG';
export const GET_LANGUAGE_COURSES_AVAILABLE_BY_NATIVE_LANGUAGE = 'GET_LANGUAGE_COURSES_AVAILABLE_BY_NATIVE_LANGUAGE';
export const GET_NATIVE_LANGUAGES_AVAILABLE_BY_SELECTED_COURSE = 'GET_NATIVE_LANGUAGES_AVAILABLE_BY_SELECTED_COURSE';
export const GET_ALL_LANGUAGE_COURSES = 'GET_ALL_LANGUAGE_COURSES';
export const SET_USER_COURSE_CONFIGURATION = 'SET_USER_COURSE_CONFIGURATION';
export const GET_WAS_USER_CONFIG_SET_FLAG = 'GET_WAS_USER_CONFIG_SET_FLAG';
export const GET_UPPER_NAV_BASED_ON_USER_CONFIG = 'GET_UPPER_NAV_BASED_ON_USER_CONFIG';
export const GET_SELECTED_COURSE_FROM_UPPER_NAV_ON_LOAD = 'GET_SELECTED_COURSE_FROM_UPPER_NAV_ON_LOAD';
export const CURRENT_ROUTE_INFO = 'CURRENT_ROUTE_INFO';
export const GET_QUIZLET_URL = 'GET_QUIZLET_URL';
export const GET_VIDEO_CLASS_URL = 'GET_VIDEO_CLASS_URL';
export const ON_SHIPPING_TAB_CHANGE = 'ON_SHIPPING_TAB_CHANGE';
export const ON_EDIT_ADDRESS_CHANGE = 'ON_EDIT_ADDRESS_CHANGE';
export const ON_EDIT_USER_PROFILE_CHANGE = 'ON_EDIT_USER_PROFILE_CHANGE';
export const ON_ADDRESS_REQUEST = 'ON_ADDRESS_REQUEST';
export const ON_LOADING_FIVE_MIN_LESSON = 'ON_LOADING_FIVE_MIN_LESSON';
export const ON_LOADING_DUMMY_DATA = 'ON_LOADING_DUMMY_DATA';
export const ON_SELECTING_CORRECTION_MODAL = 'ON_SELECTING_CORRECTION_MODAL';
export const ON_SELECTING_CORRECTION_RECORD = 'ON_SELECTING_CORRECTION_RECORD';
export const ON_LOADING_LANDING_DASHBOARD = 'ON_LOADING_LANDING_DASHBOARD';
export const ON_LOADING_LANDING_PAGE = 'ON_LOADING_LANDING_PAGE';
export const GET_BOOK_CHAPTER_URL = 'GET_BOOK_CHAPTER_URL';
export const GET_PDF_PATH_URL = 'GET_PDF_PATH_URL';
export const ON_SEARCHING_FOR_PROGRESS_BY_EMAIL_ID = 'ON_SEARCHING_FOR_PROGRESS_BY_EMAIL_ID';
export const ON_RENDERING_COURSE_REGISTRATION = 'ON_RENDERING_COURSE_REGISTRATION';
export const ON_REQUESTING_GEOGRAPHICAL_DIVISION = 'ON_REQUESTING_GEOGRAPHICAL_DIVISION';
export const ON_SEARCHING_FOR_ALREADY_ENROLLED_CONTACT = 'ON_SEARCHING_FOR_ALREADY_ENROLLED_CONTACT';
export const ON_SELECTING_ENROLLMENT_COURSES = 'ON_SELECTING_ENROLLMENT_COURSES';
export const ON_REQUESTING_COURSE_PROGRESS_STRUCTURE = 'ON_REQUESTING_COURSE_PROGRESS_STRUCTURE';
export const ON_LOADING_USER_RESOURCES_BY_COURSE_THEME = 'ON_LOADING_USER_RESOURCES_BY_COURSE_THEME';
export const ON_SUBMITTING_USER_COURSE_PROGRESS = 'ON_SUBMITTING_USER_COURSE_PROGRESS';
export const ON_RESETING_USER_PROGRESS_BY_EMAIL_ID = 'ON_RESETING_USER_PROGRESS_BY_EMAIL_ID';
export const ON_SEARCHING_FOR_PROGRESS_BY_EMAIL_ID_COURSE_CODE_ID = 'ON_SEARCHING_FOR_PROGRESS_BY_EMAIL_ID_COURSE_CODE_ID';
export const ON_LOADING_EBOOK_URL = 'ON_LOADING_EBOOK_URL';
export const ON_SUBMITTING_ENROLLEE = 'ON_SUBMITTING_ENROLLEE';
export const ON_LOGIN_FOR_ENROLLMENT = 'ON_LOGIN_FOR_ENROLLMENT';
export const ON_UPSERTING_ENROLLMENT_FOR_QUEUE = 'ON_UPSERTING_ENROLLMENT_FOR_QUEUE';
export const ON_RESET_SUBMITTING_ENROLLEE = 'ON_RESET_SUBMITTING_ENROLLEE';
export const GET_LISTENING_PRACTICE_MODULE = 'GET_LISTENING_PRACTICE_MODULE';
export const ON_LOADING_ENROLEE_BY_REGION = 'ON_LOADING_ENROLEE_BY_REGION';